import Style from './FJRating.module.less';

import { Component } from 'react';
import Trustpilot from '../../../src/assets/icon/logo_trustpilot.svg';
import Facebook from '../../../src/assets/icon/logo_facebook.svg';
import Capterra from '../../../src/assets/icon/capterra-logo.svg';
import FJSvg from '../../FJSvg/FJSvg';
import Start from '../../../src/assets/icon/star.svg';
import HalfStar from '../../../src/assets/icon/halfStar.svg';
import FJLocalStore from '../../../src/store/FJLocalStore';

export default class FJRatingSite extends Component {
  constructor(props) {
    super(props);
  }

  __drawPlatformList() {
    const { list = [], itemOnClick, language = 'en' } = this.props;

    const initList = [
      {
        className: 'Trustpilot',
        platform: Trustpilot,
        text: 'Rated 4.4 with 2000+ reviews',
        number: 2000,
        score: 4.3,
        url: 'https://www.trustpilot.com/review/flexclip.com',
      },
      {
        className: 'Facebook',
        platform: Facebook,
        text: 'Rated 4.4 with 260+ reviews',
        number: 260,
        score: 4.4,
        url: 'https://www.facebook.com/flexclip/reviews',
      },
      {
        className: 'Capterra',
        platform: Capterra,
        text: 'Rated 4.7 with 100+ reviews',
        number: 100,
        score: 4.7,
        url: 'https://www.capterra.com/p/234132/FlexClip/',
      },
    ];

    const data = Array.isArray(list) && list.length > 0 ? list : initList;

    function itemOnClickFun(item) {
      // 传递了子元素点击事件就使用外部传进来的方法，否则使用内部方法
      if (itemOnClick) {
        itemOnClick(item);
        return;
      }

      let url = item.url;

      // Trustpilot 需要根据展示的本地化进行目标跳转
      if (item.className === 'Trustpilot') {
        url = `https://${language === 'en' ? 'www' : language}.trustpilot.com/review/flexclip.com`;
      }
      window.open(url);
    }

    return data.map((item, index) => {
      const startNumber = new Array(parseInt(item.score) || 0).fill(null).map((item, index) => index + 1);
      const halfStart = (item.score || 0) % 1 !== 0;

      const text = FJLocalStore._('feed-back-score').replace('aaa', item.score).replace('bbb', item.number);
      return (
        <li
          className={Style.platformItem}
          key={index}
          onClick={() => {
            itemOnClickFun(item);
          }}
        >
          <FJSvg src={item.platform} />
          <div className={Style.platformRight}>
            <div className={`${Style.startBox} ${Style[item.className]}`}>
              {startNumber.map(number => (
                <FJSvg key={String(index) + number} src={Start} />
              ))}
              {halfStart && <FJSvg src={HalfStar} />}
            </div>
            <span className={Style.text}>{text}</span>
          </div>
        </li>
      );
    });
  }

  render() {
    return <ul className={Style.platformList}>{this.__drawPlatformList()}</ul>;
  }
}
