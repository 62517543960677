import React, { Component } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/core';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/bundle';
import Style from './SwiperNavigation.module.less';
import classname from 'classnames';
import FJSvg from '../../FJSvg/FJSvg';
import arrowRight from '../../../src/assets/img/home/arrowRight.svg';
import FJUtil from '../../../src/util/FJUtil';
import SwiperPagination from '../SwiperPagination/SwiperPagination';

export default class FJTemplateListSwiper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spaceBetween: 20,
      speed: 2000,
      isBegin: true,
      isEnd: false,
    };
    this.swiperRef = React.createRef();
    this.timer = null;
  }

  componentDidMount() {
    FJUtil.addEvent(window, 'resize', this.updateUI);
    this.updateUI();
  }

  componentWillUnmount() {
    FJUtil.removeEvent(window, 'resize', this.updateUI);
    this.timer && clearTimeout(this.timer);
  }

  updateUI = () => {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }

    this.timer = setTimeout(() => {
      if (this.swiperRef.current) {
        const swiper = this.swiperRef.current.swiper;
        swiper.update(); // 调用Swiper实例的update方法
        clearTimeout(this.timer);
        this.timer = null;
      }
    }, 500);
  };

  leftIconClick = () => {
    if (this.state.speed !== 0) {
      this.setState({
        speed: 200,
      });
    }
    if (this.swiperRef.current) {
      const swiper = this.swiperRef.current.swiper;
      swiper.autoplay.stop(); // 停止自动轮播动画
      setTimeout(() => {
        swiper.slidePrev();
      });
    }
  };

  rightIconClick = () => {
    if (this.swiperRef.current) {
      if (this.state.speed !== 0) {
        this.setState({
          speed: 200,
        });
      }
      const swiper = this.swiperRef.current.swiper;
      swiper.autoplay.stop(); // 停止自动轮播动画

      setTimeout(() => {
        swiper.slideNext();
      });
    }
  };

  render() {
    const { clientData = [], drawSwiperSlideItem, delay = 0, swiperStyle = {} } = this.props;
    let { isBegin, isEnd } = this.state;

    let classLeft = classname(Style.preBtn, Style.btn, isBegin ? Style.none : '', 'swiper-button-left');
    let classRight = classname(Style.nextBtn, Style.btn, isEnd ? Style.none : '', 'swiper-button-right');

    return (
      <Swiper
        ref={this.swiperRef}
        spaceBetween={this.state.spaceBetween}
        slidesPerView='auto'
        modules={[Navigation, Autoplay]}
        navigation={{
          enabled: false,
          nextEl: '.swiper-button-right',
          prevEl: '.swiper-button-left',
        }}
        speed={this.state.speed}
        loop={true}
        autoplay={{
          enabled: true,
          delay,
        }}
        onTransitionEnd={data => {
          this.setState({
            isBegin: data.isBeginning,
            isEnd: data.isEnd,
          });
        }}
        style={swiperStyle}
      >
        {clientData.map((item, index) => {
          return (
            <SwiperSlide key={index} className={Style.swiperSlide}>
              {drawSwiperSlideItem(item)}
            </SwiperSlide>
          );
        })}
        <div className={classLeft} onClick={this.leftIconClick}>
          <FJSvg src={arrowRight} className={Style.leftIcon} />
        </div>
        <div className={classRight} onClick={this.rightIconClick}>
          <FJSvg src={arrowRight} />
        </div>
      </Swiper>
    );
  }
}
